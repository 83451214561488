<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" >
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 160px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 160px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="审批流程">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedFlow" :options="flowList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">删除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">上报</el-button>
                                </span>
                                <h3 class="tit">公路列表</h3>
                            </div>
                            <div class="box-content">

                                <el-table  @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column fixed prop="" align="center" :width="180" label="操作">
                                        <template slot-scope="scope">
<!--                                            <span class="newicon iconsyes" @click="showMaps(scope.row)"><i class="el-icon-location icons iconsyes"></i>定位</span>-->
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
                                            <span class="newicon iconsyes" v-if="scope.row.IsShowAudit" @click="checkPro(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>审核</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <el-table-column prop="XLXZ"  align="center" label="流程状态" :width="150">
                                        <template slot-scope="scope">
                                            <el-tooltip  :content="scope.row.Remarks" placement="top" v-if="scope.row.FlowState == -1 || scope.row.FlowState == -2">
                                            <div v-if="scope.row.FlowState == -1"><el-tag type="danger">市级驳回</el-tag></div>
                                            <div v-if="scope.row.FlowState == -2"><el-tag type="danger">省交通厅驳回</el-tag></div>
                                            </el-tooltip>
                                            <div v-if="scope.row.FlowState == 1"><el-tag>市级待审核</el-tag></div>
                                            <div v-if="scope.row.FlowState == 2"><el-tag>省级交通厅待审核</el-tag></div>
                                            <div v-if="scope.row.FlowState == 3"><el-tag type="success">审核通过</el-tag></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="SZS"  label="所在地市" :width="100" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{unique(scope.row.SZS)}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="SZX" label="所属县（市）" :width="120" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{unique1(scope.row.SZX)}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
                                    <el-table-column prop="SSWXMMC"  align="center" label="对应部“十四五”交通运输专项建设规划项目名称" show-overflow-tooltip :width="320" ></el-table-column>
                                    <el-table-column prop="JSXZ"  align="center" label="建设性质" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="ZDMJ"  align="center" :width="140" label="占地面积(平方米)" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="JZMJ"  align="center" :width="140" label="建筑面积(平方米)" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="SCNL"  align="center" :width="180" label="生产能力(货物处理吨/日)" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="ZTZ" label="总投资(万元)" :width="120" align="center" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div style="text-align: right">
                                                {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" size="1183px" :title="XMMC"  class="editModal">
                <el-form  :disabled="!isLock" :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card">
                        <el-tab-pane label="基本信息" name="0">
                            <div class="col">
                                <div class="col-1">
                                    <el-divider>基本信息</el-divider>
                                    <el-form-item  label="计划年度"  prop="JHND" >
                                        <el-input disabled placeholder="计划年度" v-model="detail.JHND" ></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目所在地" prop="buildAddress"  :rules="[{ required: true, message:'请选择项目所在地',trigger: 'change' }]">
                                        <el-cascader :key="parseInt(Math.random()*10000)" size="medium" v-model="detail.buildAddress" :options="regions" :props="{ multiple: true}" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称" prop="XMMC" :rules="[{ required: true, message:'请输入项目名称',trigger: 'blur' }]">
                                        <el-input placeholder="请输入项目名称" v-model="detail.XMMC" ></el-input>
                                    </el-form-item>
                                    <div style="color: #F54A26;font-size: 10px">如已批复前期工作应与批复文件名称保持一致,并注所处城市名</div>
                                    <el-form-item label='对应前期项目名称'>
                                        <div class="dwmcWrap">
                                            <el-tooltip  :content="XMMC" placement="top" v-if="XMMC!= ''">
                                                <div class="dwmc" @click="openTable">{{XMMC}}</div>
                                            </el-tooltip>
                                            <div v-else class="dwmc" @click="openTable">{{XMMC}}</div>
                                            <div class="plus"><i @click="openTable" class="el-icon-circle-plus"></i></div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="占地面积" prop="ZDMJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="占地面积"  v-model="detail.ZDMJ"><template #suffix>平方米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="建筑面积" prop="JZMJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="建筑面积"  v-model="detail.JZMJ"><template #suffix>平方米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="生产能力" prop="SCNL" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="生产能力"  v-model="detail.SCNL"><template #suffix>吨/日</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="建设性质"  prop="JSXZ" :rules="[{ required: true, message:'请选择建设性质',trigger: 'change' }]">
                                        <el-select placeholder="请选择建设性质" v-model="detail.JSXZ">
                                            <el-option label="新建" value="新建"></el-option>
                                            <el-option label="新改建" value="新改建"></el-option>
                                            <el-option label="改扩建" value="改扩建"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="开工年">
                                        <el-date-picker  style="width: 330px"

                                                         format="yyyy"
                                                         value-format="yyyy-MM-dd"
                                                         v-model="detail.KGN" type="year" placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="完工年">
                                        <el-date-picker  style="width: 330px"

                                                         format="yyyy"
                                                         value-format="yyyy-MM-dd"
                                                         v-model="detail.WGN" type="year" placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="col-1">
                                    <el-divider>监管信息</el-divider>
                                    <el-form-item  label="日常监管直接责任单位"  prop="JGZRDW" >
                                        <el-input  placeholder="日常监管直接责任单位" v-model="detail.JGZRDW" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="监管责任人"  prop="JGZRR" >
                                        <el-input  placeholder="监管责任人" v-model="detail.JGZRR" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="责任单位" prop="ZRDW" >
                                        <el-input  placeholder="责任单位" v-model="detail.ZRDW" ></el-input>
                                    </el-form-item>
                                    <el-form-item label="责任单位联系电话"  prop="ZRDWDH">
                                        <el-input placeholder="责任单位联系电话" v-model="detail.ZRDWDH" ></el-input>
                                    </el-form-item>
                                    <el-form-item label="责任人"  prop="ZRR">
                                        <el-input placeholder="责任人" v-model="detail.ZRR" ></el-input>
                                    </el-form-item>
                                    <el-form-item label="责任人联系电话" prop="ZRRDH">
                                        <el-input placeholder="责任人联系电话" v-model="detail.ZRRDH" ></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-1">
                                    <el-divider>前期工作情况</el-divider>
                                    <el-form-item  label="工可批复文号"  prop="QQGK" >
                                        <el-input  placeholder="工可批复文号" v-model="detail.QQGK" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="初设批复文号"  prop="QQCS" >
                                        <el-input  placeholder="初设批复文号" v-model="detail.QQCS" ></el-input>
                                    </el-form-item>
                                    <el-divider>地方配套使用 <br>一般债券情况</el-divider>
                                    <el-form-item :label="nowYear+'年及以前已使用'"  prop="YSYZQ">
                                        <el-input :autosize="{ minRows: 2, maxRows: 4}" style="width: 330px" placeholder="" type="textarea" v-model="detail.YSYZQ"></el-input>
                                    </el-form-item>
                                    <el-form-item :label="(nowYear+1)+'年需求'"  prop="NextZQXQ">
                                        <el-input :autosize="{ minRows: 2, maxRows: 4}" style="width: 330px" placeholder="" type="textarea" v-model="detail.NextZQXQ"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane  label="投资" name="1">
                            <div style="display: flex;align-items: center" class="tztop">
                                <el-form-item label="总投资"  prop="ZTZ">
                                    <el-input  placeholder="请输入总投资" v-model="detail.ZTZ" ></el-input>
                                </el-form-item>
                                <el-form-item  label="中央投资（车购税）"  prop="ZYTZ">
                                    <el-input  placeholder="中央投资（车购税）" v-model="detail.ZYTZ" ></el-input>
                                </el-form-item>
                                <el-form-item  label="省级补助资金"  prop="NextSJBZZJ">
                                    <el-input placeholder="省级补助资金" v-model="detail.NextSJBZZJ" ></el-input>
                                </el-form-item>
                            </div>
                            <table style="text-align:center;margin-top: 20px"  class="littleTable">
                                <tr>
                                    <td colspan="8" style="background: #F5F7FA">
                                        <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">预计到{{nowYear}}年底累计完成投资</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2" style="width: 270px">合计</div>
                                    </td>
                                    <td >
                                        <div class="label2" style="width: 270px" >中央车购税</div>
                                    </td>
                                </tr>
                                <tr >
                                    <td class="ty">
                                        <el-form-item  prop="WCTZHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input style="width: 270px" size="medium" v-model="detail.WCTZHJ" placeholder="合计"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
                                        <el-form-item prop="WCZYCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input style="width: 270px" size="medium" v-model="detail.WCZYCGS" placeholder="中央车购税"></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                            </table>
                            <table style="text-align:center;margin-top: 20px"  class="littleTable2">
                                <tr>
                                    <td colspan="8" style="background: #f5f7fa">
                                        <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">{{nowYear+1}}年建议计划</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2" >合计</div>
                                    </td>
                                    <td>
                                        <div class="label2" style="line-height: unset">提前下达批次 <br>申请车购税</div>
                                    </td>
                                    <td>
                                        <div class="label2" >第二批申请车购税</div>
                                    </td>
                                    <td>
                                        <div class="label2" >地方（企业）自筹</div>
                                    </td>
                                    <td>
                                        <div class="label2">主要建设内容</div>
                                    </td>
                                    <td>
                                        <div class="label2">新增生产能力</div>
                                    </td>
                                </tr>
                                <tr >
                                    <td class="jhky">
                                        <el-form-item  prop="NextHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input size="medium" v-model="detail.NextHJ" placeholder="合计"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="jhky">
                                        <el-form-item prop="SPCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input size="medium" v-model="detail.SPCGS" placeholder="提前下达批次申请车购税"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="jhky">
                                        <el-form-item prop="EPCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  size="medium" v-model="detail.EPCGS" placeholder="第二批申请车购税"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="jhky">
                                        <el-form-item  prop="DFZC" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  size="medium" v-model="detail.DFZC" placeholder="地方（企业）自筹"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="jhky">
                                        <el-form-item  prop="ZYJSNR" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  size="medium" v-model="detail.ZYJSNR" placeholder="主要建设内容"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="jhky">
                                        <el-form-item  prop="XZSCNL" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  size="medium" v-model="detail.XZSCNL" placeholder="新增生产能力"></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                            </table>

                        </el-tab-pane>
                        <el-tab-pane label="附件" name="2">
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">建议计划申请文件</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'JYJHSQFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="file" v-for="(item,index) in detail.JYJHSQFJ" :key="index">
                                            <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                <!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                            </a>
                                            <!--                                                </div>-->
                                            <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'JYJHSQFJ')">
                                                <template #reference>
                                                    <i style="color: #666666" class="el-icon-delete" ></i>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">批复文件和证明材料（PDF文档）</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SGTFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <div class="file" v-for="(item,index) in detail.SGTFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SGTFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">资金安排意见</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'ZJAPYJFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <div class="file" v-for="(item,index) in detail.ZJAPYJFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'ZJAPYJFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">项目地理信息</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'XMDZDTFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <div class="file" v-for="(item,index) in detail.XMDZDTFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'XMDZDTFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>


                    <div class="footer">
                        <el-button type="primary" @click="save">上报</el-button>
                        <el-button @click="cancel">取消</el-button>
                    </div>
                </el-form>
            </el-drawer>
            <!--        十四五项目弹窗-->
            <el-drawer :visible.sync="assign" :title="detail.XMMC" size="80%">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" style="display:flex">
                    <div class="search">
                        <el-form-item label="项目名称">
                            <el-input style="width: 300px" v-model="formInline.XMMC" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="searchCompanyList">搜索</el-button>
                        <el-button type="primary" @click="confirmCompany">确定</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table :header-cell-class-name="cellClass" @selection-change="selectCompany"  ref="multipleTable" :data="companyData"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                        <el-table-column fixed type="selection" width="55"  :selectable="checkSelectable"/>
                        <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                        <el-table-column prop="SZDS"  label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SZX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMFRDW" label="项目法人单位" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                        <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
                        <el-table-column prop="SSWGHXMMC"  align="center" label="对应部“十四五”交通运输专项建设规划项目名称" show-overflow-tooltip :width="320" ></el-table-column>
                        <el-table-column prop="QQGZJZ" label="前期工作进展" :width="400" align="center" show-overflow-tooltip>
                            <el-table-column prop="JSGMGS" label="工可研" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.GKBLZT == 0 ? '未开展' : scope.row.GKBLZT == '1' ? '办理中' : '已办结'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="初步设计" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.CBSJBLZT == 0 ? '未开展' : scope.row.CBSJBLZT == '1' ? '办理中' : '已办结'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="施工图" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.SGTBLZT == 0 ? '未开展' : scope.row.SGTBLZT == '1' ? '办理中' : '已办结'}}
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="ZTZ" label="总投资(万元)" :width="120" align="center" sortable show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div style="text-align: right">
                                    {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="JSXZ" label="建设性质" align="center" show-overflow-tooltip :width="150"></el-table-column>

                        <el-table-column prop="XMXZ" label="项目性质" align="center" show-overflow-tooltip :width="150"></el-table-column>

                        <el-table-column label="建设规模" :width="150"  align="center">
                            <el-table-column prop="JSGMHJ" label="合计(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMHJ ? scope.row.JSGMHJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="高速(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMYJ" label="一级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMEJ" label="二级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMSJ" label="三级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column label="特大桥" width="50" align="center">
                                <el-table-column prop="JSGMTDQ" label="座数(座)" width="90" align="right"></el-table-column>
                                <el-table-column prop="JSGMTDQYM" label="延米数(米)" width="90" align="right"></el-table-column>
                            </el-table-column>
                            <el-table-column label="隧道" width="50" align="center">
                                <el-table-column prop="JSGMSD" label="座数(座)" width="90" align="right"></el-table-column>
                                <el-table-column prop="JSGMSDYM" label="延米数(米)" width="90" align="right"></el-table-column>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="QQGZFZR" label="前期工作负责人" :width="150" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="LXDH" label="联系电话" :width="150" align="center" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30,40]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>
        <!--        审核窗口-->
        <el-dialog
                :visible.sync="showCheck"
                title="审核"
                width="30%"
        >
            <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="remarks">
            </el-input>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="checkProject(false)">拒绝</el-button>
        <el-button type="primary" @click="checkProject(true)">通过</el-button>
      </span>
            </template>
        </el-dialog>
        <!--建设单位编辑弹窗-->
        <el-dialog :title="dialogTableVisibleData.RouteName" :visible.sync="dialogTableVisibleMap" width="100%" class="mapdialog mapdialog1">
            <PlanForm :key="parseInt(Math.random()*10000)" v-if="dialogTableVisibleMap"  :dialogData="dialogTableVisibleData" />
        </el-dialog>


    </div>
</template>
<script>
    import PlanForm from "../frontMap/countrySideMap";
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    import http from "../../api/http";
    export default {
        name: "countrySide",
        components: {PlanForm},
        data() {
            return {
                nowYear:new Date().getFullYear(),
                remarks:'',
                showCheck: false,
                isLock: true,
                assign: false,
                formInline:'',
                activeName:'0',
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                required: false,
                title:'新增建设单位',
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{},
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                XMMC:'',
                addStatus: true,
                tableHeight:0, //表格高度
                /**查询条件 begin*/
                xmmc: "", //项目名称
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                region: [""], //已选中行政区划
                /**查询条件 end*/
                checkList: [],
                checkedFlow:[],
                flowList:[{value:'3',label:'审核通过',disabled: false},{value:'-1,-2',label:'驳回',disabled: false},{value:'1,2',label:'待审核',disabled: false}],
                jsxzList: [{value:'新建',label:'新建',disabled: false},{value:'新改建',label:'新改建',disabled: false},{value:'改扩建',label:'改扩建',disabled: false}],
                xmlxList: [{value:'乡镇通三级及以上公路',label:'乡镇通三级及以上公路',disabled: false},{value:'较大人口规模自然村通硬化路',label:'较大人口规模自然村通硬化路',disabled: false},{value:'建制村通双车道',label:'建制村通双车道',disabled: false},{value:'资源路',label:'资源路',disabled: false},{value:'旅游路',label:'旅游路',disabled: false},{value:'产业路',label:'产业路',disabled: false},{value:'公路提质改造',label:'公路提质改造',disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                searchAll:[],
                newAdd:false,
                showEdit:false,
                isAdmin: false,
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                companyData:[],
                total1:0,
            };
        },
        mounted() {
            var that = this
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 150;
            }, 100);
            this.getRegion();
            this.search();
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        computed:{

        },
        methods: {
            //所在市去重
            unique(arr) {
                var a = JSON.parse(arr)
                console.log(a)
                var city = []
                a.map((item)=>{
                    city.push(item[0])
                })
                city =  Array.from(new Set(city))
                return city =  city.toString()
                // return arr.filter((arr) => !res.has(arr.projectNo) && res.set(arr.projectNo, 1));
            },
            unique1(arr){
                var a = JSON.parse(arr)
                var city = []
                a.map((item)=>{
                    city.push(item[1])
                })
                return city =  city.toString()
            },
            checkPro(row){
                this.showCheck = true
                this.id = row.Id
                console.log(this.id)
            },
            checkProject(val){
                console.log(val)
                this.http.post('/api/Plan_filling/AuditFilling?id='+this.id+'&isPassed='+val+'&remarks='+this.remarks,{id:this.id,remarks:this.remarks,isPassed:val}).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.remarks = ''
                        this.showCheck = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            checkSelectable(row) {
                if (this.formInline.dwmc && row.dwmc.indexOf(this.formInline.dwmc) < 0) {
                    return false;
                } else {
                    return true;
                }
            },
            //选择建设单位
            selectCompany(val){
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;

                }
            },
            searchCompanyList(){
                this.getCompanyList(1)
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                if (this.chek.length != 0 ){
                    this.XMMC = this.chek[0].XMMC
                    this.SSWSSWGHXMID =this.chek[0].ID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                }

                this.assign = false
            },
            openTable(){
                var that = this
                this.assign = true
                this.formInline = {}
                this.currentPage1 = 1
                this.getCompanyList()
            },
            getCompanyList(page){
                var that = this
                this.http.post('/api/Plan_high_national_early/GetData',  this.postData1(page)).then(res=> {
                    this.companyData = res.rows
                    this.total1 = res.total
                    this.$nextTick(() => {
                        that.$refs.multipleTable.toggleRowSelection(that.companyData.find((item) => {
                                if (item.XMMC == that.detail.SSWXMMC){
                                    return item
                                }
                            }),
                            true);
                    })
                })
            },
            postData1(page){
                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.formInline.XMMC,
                    DisplayType: "like",
                };
                if(page){
                    this.currentPage1 = page
                }
                var postData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_xmmc
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            showMaps(row) {
                this.http.post('/api/Ncgl_flag/getItemById?id='+row.ID,{}).then(res=>{
                    // if(res.status){
                    row.XZFW = res.XZFW
                    row.BaseInfoID = res.BaseInfoID ? res.BaseInfoID : row.ID
                    row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    // }
                })
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },

            closeDrawer(){
                this.$refs.detailForm.resetFields()
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            confirmChoose(){

            },
            addRoad(){
                var that = this
                this.addStatus = true
                this.isLock = true
                this.XMMC = ''
                this.currentPage1 = 1
                this.showAddModel = true
                this.detail = {
                    JYJHSQFJ:[],
                    SGTFJ:[],
                    ZJAPYJFJ:[],
                    DFZFZJCNHFJ:[],
                    XMDZDTFJ:[],
                }
            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {}
            },
            //删除图片
            deleted(index,type){
                this.detail[type].splice(index,1)
            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.JSGMYJ == null || this.detail.JSGMYJ ==  '' || !reg.test(this.detail.JSGMYJ) ? 0 : parseFloat(this.detail.JSGMYJ)
                var xz7 = this.detail.JSGMGS == null || this.detail.JSGMGS ==  '' || !reg.test(this.detail.JSGMGS) ? 0 : parseFloat(this.detail.JSGMGS)
                var xz2 = this.detail.JSGMEJ == null || this.detail.JSGMEJ ==  '' || !reg.test(this.detail.JSGMEJ) ? 0 : parseFloat(this.detail.JSGMEJ)
                var xz3 = this.detail.JSGMSJ == null || this.detail.JSGMSJ ==  '' || !reg.test(this.detail.JSGMSJ) ? 0 : parseFloat(this.detail.JSGMSJ)
                var xz4 = this.detail.JSGMSI == null || this.detail.JSGMSI ==  '' || !reg.test(this.detail.JSGMSI) ? 0 : parseFloat(this.detail.JSGMSI)
                var xz5 = this.detail.JSGMDQ == null || this.detail.JSGMDQ ==  '' || !reg.test(this.detail.JSGMDQ) ? 0 : parseFloat(this.detail.JSGMDQ)/1000
                var xz6 = this.detail.JSGMSD == null || this.detail.JSGMSD ==  '' || !reg.test(this.detail.JSGMSD) ? 0 : parseFloat(this.detail.JSGMSD)/1000
                this.detail.JSGMHJ = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6),xz7)
            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
            //附件处理
            dealFj(data){
                console.log(data)
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }
            },
            dealSaveFj(data){
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                console.log( pfwjString.join(';'))
                return pfwjString.join(';')
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        params.FillingType = '7'
                        params.jhnd = new Date().getFullYear()+1
                        params.JYJHSQFJ = this.dealSaveFj(params.JYJHSQFJ)
                        params.SGTFJ = this.dealSaveFj(params.SGTFJ)
                        params.ZJAPYJFJ =this.dealSaveFj(params.ZJAPYJFJ)
                        params.DFZFZJCNHFJ =this.dealSaveFj(params.DFZFZJCNHFJ)
                        params.XMDZDTFJ =this.dealSaveFj(params.XMDZDTFJ)
                        // params.newLand = params.newLand == '' ?  null : Number(params.newLand)
                        // params.buildScale = params.buildScale == '' ? null : Number(params.buildScale)
                        params.SZS = JSON.stringify(params.buildAddress)
                        params.SZX =  JSON.stringify(params.buildAddress)
                        params.SSWXMID = this.SSWSSWGHXMID
                        params.SSWXMMC = this.XMMC
                        console.log(params)
                        if(this.addStatus){
                            params.id='00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_filling/AddFilling',params).then(res=>{
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                    this.$refs.detailForm.resetFields()
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }else{
                            this.http.post('/api/Plan_filling/UpdateFilling',params).then(res=>{
                                if(res.status){
                                    this.http.post('/api/Plan_filling/SaveFilling?id='+res.data.id,{}).then(res=>{
                                        if(res.status){
                                            this.showAddModel = false
                                            this.$message.success(res.message)
                                            this.search()
                                            this.$refs.detailForm.resetFields()
                                            this.addStatus = true
                                        }
                                    })
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    }
                });
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                console.log(file)
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_filling/upload", form).then((res) => {
                    this.uploadLoading = false;
                    arrs.forEach((v) => {
                        console.log(v)
                        this.detail[type].push({ url: res.data, name: v });
                    });
                });
                console.log(type)
            },
            openDetailsEdit(row) {
                console.log(row)
                this.isLock = row.IsShowEdit
                var that = this
                this.showAddModel = true
                this.addStatus = false
                this.activeName = '0'
                this.http.post('/api/Plan_filling/getItemById?id='+row.Id,{}).then(res=>{
                    console.log(res)
                    res.buildAddress = JSON.parse(res.SZS)
                    this.XMMC = res.SSWXMMC
                    this.detail = res
                    this.detail.JYJHSQFJ = this.dealFj(this.detail.JYJHSQFJ)
                    this.detail.SGTFJ = this.dealFj(this.detail.SGTFJ)
                    this.detail.ZJAPYJFJ =this.dealFj(this.detail.ZJAPYJFJ)
                    this.detail.DFZFZJCNHFJ =this.dealFj(this.detail.DFZFZJCNHFJ)
                    this.detail.XMDZDTFJ =this.dealFj(this.detail.XMDZDTFJ)
                })
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_high_national_early/templateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期项目表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        res.map((r) => {
                            r.value = r.label;
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Plan_filling/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postData() {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "RouteName",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //所属县
                var query_szs = {
                    Name: "SZX",
                    Value: szds,
                    DisplayType: "like",
                };
                var query_ssx = {
                    Name: "SZS",
                    Value: ssx,
                    DisplayType: "like",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "BuildNature",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目类型
                var xmlxArray = [];
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_xmlx = {
                    Name: "ProjectType",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };
                var query_type = {
                    Name: "FillingType",
                    Value: '7',
                    DisplayType: "Equal",
                };
                var query_Flow = {
                    Name: "FlowState",
                    Value: this.checkedFlow.join(","),
                    DisplayType: "checkbox",
                };

                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_Flow,
                        query_szs,
                        query_ssx,
                        query_xmmc,
                        query_xmlx,
                        query_jsgm,
                        query_type
                    ]),
                };
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.gllx = "";
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getCompanyList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getCompanyList()

            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }

    .roadFront{
        .jhky{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        .ty{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /deep/.el-collapse-item__content {
            padding:10px 15px 20px 15px  !important;
        }
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
            }
            .tz{
                /deep/.el-input__inner{
                    width: 280px;
                }
                /deep/.el-form--inline .el-form-item{
                    margin-right: 0px;
                }
            }
            .jhky{
                /deep/.el-input__inner{
                    width: 135px;
                }
            }
            .ty{
                /deep/.el-input__inner{
                    width: 270px;
                }
            }
            .tztop{
                /deep/.el-form-item__label{
                    background: unset;
                }
                /deep/.el-input__inner {
                    width: 260px;
                }
            }
            .littleTable{
                /deep/.el-input__inner {
                    width: 270px;
                    border: none;
                    height: 45px;
                }
            }
            .littleTable2{
                /deep/.el-input__inner {
                    width: 190px;
                    /*margin: 2px 4px;*/
                    border: none;
                    height: 45px;
                }
            }
        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #f2f8fe;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /deep/ .el-input--suffix{
        width: 330px;
    }
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 4px;
        .dwmc{
            padding-left: 10px;
            text-align: left;
            width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 40px;
            background: #ffffff;
            border-radius: 4px 0 0 4px;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 40px;
            background: #ffffff;
            border-radius: 0px 4px 4px 0;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }

    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        height: 47px;
        background: #F5F7FA;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        height: 47px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    .cont2{
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .flexs-m {
        display: flex;
        align-items: initial;
    }
    .title {
        margin-top: 20px;
        background: #f2f8fe;
        display: flex;
        align-content: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        i {
            line-height: 32px;
            margin-right: 12px;
            font-size: 18px;
        }
        span {
            display: block;
            width: 98px;
            height: 32px;
            background: #409eff;
            line-height: 32px;
            font-size: 16px;
            font-family: Segoe UI;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
        }
    }

    .name {
        width: 220px;
        height: 42px;
        background: #fafafa;
        text-align: center;
        line-height: 42px;
        font-weight: 600;
    }
    .value {
        display: flex;
        align-items: center;
        width: calc(100% - 168px);
        padding: 0 10px;
        // height: 42px;
        line-height: 42px;
        font-weight: 400;
        box-sizing: border-box;
        color: #666666;
    }
    .name1 {
        width: 74px;
        line-height: normal;
        label {
            width: 100%;
            padding: 0;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-family: Segoe UI;
            font-weight: 400;
            color: #ffffff;
            i {
                font-style: normal !important;
            }
        }
    }
    .fileWrap{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .value1 {
        display: flex;
        align-items: center;
        width: calc(100% - 168px);
        display: flex;
        height: auto;
        label {
            display: inline-block;
            vertical-align: baseline;
        }
        .files {
            display: block;
            // border: 1px solid #b3d8ff;
            // height: 36px;
            // line-height: 36px;
            border-radius: 4px;
            position: relative;
            top: 2px;
            .wj {
                margin-bottom: 5px;
                border: 1px solid #b3d8ff;
                height: 30px;
                vertical-align: middle;
                line-height: 30px;
                display: inline-block;
                margin-left: 10px;
                border-radius: 4px;
                padding: 0 5px;
                position: relative;
                top: -2px;
            }
        }
    }
    .name3 {
        background: none;
    }
    .file{
        margin-right: 10px;
        display:flex;
        align-items: center;
        margin-top: 0px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 20px;
            width: 330px;
            background: #F2F8FE;
            min-height: 800px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
</style>

